import React, { useContext } from "react";
import { useStaticQuery, graphql, navigate } from "gatsby";
import { Container } from "theme-ui";
import Layout from "@solid-ui-layout/Layout";
import Seo from "@solid-ui-components/Seo";
import Divider from "@solid-ui-components/Divider";
import StackTitle from "@solid-ui-components/StackTitle";
import Tabs from "@solid-ui-components/Tabs";
import Features from "@solid-ui-blocks/FeaturesWithPhoto/Block10";
import { normalizeBlockContentNodes } from "@blocks-helpers";
import AppWrapper, { AppContext } from '@helpers/appContext';
import theme from "./_theme";

const Downloads = (props) => {
  const { location } = props;
  const { selectedLanguage } = props;
  const { authenticated } = useContext(AppContext);
  const { allBlockContent } = useStaticQuery(query);
  
  const content = normalizeBlockContentNodes(allBlockContent?.nodes);

  const description = content[`description.${selectedLanguage}`];
  const tabs = content[`tab-features.${selectedLanguage}`];

  const isRestrictedRoute = (!authenticated && location?.pathname?.startsWith('/downloads'));

  if (isRestrictedRoute && typeof window !== 'undefined') {
    navigate('/');
    return null;
  };

  return (
    <Layout theme={theme} {...props}>
      <Seo title="Downloads" />

      <Divider space="5" />
      <Divider space="5" />

      <Container variant="full">
        <StackTitle
          kicker={description.kicker}
          title={description.title}
          lines={[description.text_1]}
          align="center"
          space={0}
        />
      </Container>
      
      <Divider space="4" />

      <Container variant="wide">
        <Tabs space={4}>
          {tabs.collection?.map((data, index) => (
            <Features key={`item-${index}`} content={data} />
          ))}
        </Tabs>
      </Container>

      <Divider space="5" />
    </Layout>
  );
};

const query = graphql`
  query siteDownloadssBlockContent {
    allBlockContent(
      filter: {
        page: {
          in: [
            "site/downloads/description"
            "site/downloads/tabs"
            "site/shared/book-demo"
          ]
        }
      }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`;

const DownloadsPage = (props) => (
  <AppWrapper>
    <Downloads {...props}/>
  </AppWrapper>
);

export default DownloadsPage;
